<div class="locator-box">
    <div class="head-bar">
        <!-- <h5>{{locatorType | titlecase}} List</h5> -->
        <h5 class="w-100 text-center">{{locatorType | titlecase}} List</h5>
        <img (click)="closeModal()" src="assets/images/cross.png" alt="" />
    </div>

    <div class="exceed-popup" *ngIf="hasExceeded">
        <p>
            <span (click)="hasExceeded=false"><img src="assets/images/cross.png" alt="" /></span>
            You have to search manually, if you want to see more companies besides these {{copyArr.length}} companies.
        </p>
    </div>
    
    <div class="alphabets-search d-none" *ngIf="isLocatorExist() && locatorObj?.country=='India'">
        <span *ngFor="let item of alphabets;" (click)="getLocatorsByLetter(item)">{{item}}</span>
    </div>
    
    
    <div class="copy-tab-head">
        <span><input type="text" [formControl]="searchControl" placeholder="Search {{locatorType}}" id="" /></span>
    </div>
    <div class="table-container" *ngIf="copyArr.length>0 && !isApiProcess">
        
        <cdk-virtual-scroll-viewport [itemSize]="10">
            <table id="locatorTable">
                <tbody>
                    <tr class="selectAll" *ngIf="isSelectAllShow">
                        <td><input type="checkbox" (click)="selectAllValues(perPageLocators)" id=""></td>
                        <td colspan="2">Select All</td>
                    </tr>
                    <tr tabindex="-1" *cdkVirtualFor="let item of perPageLocators;let i=index;">
                        <td><input type="checkbox" [checked]="isMainSelect" (click)="selectSingleValue($event, item)" id=""></td>
                        <td colspan="2">{{locatorType=='exporter' ? item?.Exp_Name : item?.Imp_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </cdk-virtual-scroll-viewport>
        <div class="copy-tab-foot">
            <div class="pagination" *ngIf="locatorObj?.country=='India'">
                <img src="assets/images/rightArrow.png" (click)="partitionLocator('prev')" alt="">
                <span>{{currentPageNum+1}}</span>
                <img src="assets/images/rightArrow.png" (click)="partitionLocator('next')" alt="">
                <pre>Upto {{copyArr.length}} records</pre>
            </div>
            <div class="apply-btn"><button class="btn btn-danger" (click)="applyLocator()">Apply</button></div>
        </div>
    </div>
    <div class="loader" *ngIf="copyArr.length==0 && isError && !isApiProcess">
        <h5>No Data Found</h5>
    </div>

    <div class="loader" *ngIf="isApiProcess">
        <img src="assets/images/loader.png" alt="">
        <span>Please wait...</span>
    </div>
</div>
